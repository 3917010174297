const faqs = [
  {
    question: "¿Qué vacunas preciso para ingresar a Europa?",
    answer: (
      <div>
        <p>La lista de vacunas aprobadas de acuerdo con la Organización Mundial de la Salud (OMS) para entrar a la Unión Europea son: </p> <br/>
        <p>- Pfizer / BioNTech.</p>
        <p>- Moderna.</p>
        <p>- Oxford / AstraZeneca.</p>
        <p>- Janssen / Johnson & Johnson.</p>
        <p>- Nuvaxovid / Novavax.</p>
        <p>- Covovax.</p>
        <p>- CanSino.</p>
        <p>- Covishield.</p>
        <p>- Covaxin.</p>
        <p>- Covilo.</p>
        <p>- CoronaVac.</p>
      </div>
    ),
  },
  {
    question: "¿Qué es el pase sanitario europeo?",
    answer: (
      <div>
        <p>El pase sanitario también es conocido como pasaporte COVID como medida implementada por los países de la Unión Europea. Deberás presentar este certificado digital para demostrar que has sido vacunado con las vacunas autorizadas en Europa y debe contar con las siguientes características: </p> <br/>
        <p>- Gratuito, accesible y seguro.</p>
        <p>- Formato digital o en papel (este no debe presentarse en mal estado).</p>
        <p>- Código QR.</p>
        <p>- Datos del ciudadano, incluye: nombre, apellidos y fecha de nacimiento.</p>
        <p>- País miembro emisor.</p>
        <p>- Disponible en la lengua nacional e inglés.</p>
        <p>- Válido en todos los países de la UE.</p> <br/>
        <p>Una persona que posea un certificado COVID digital de la UE válido no debe, en principio, estar sujeta a restricciones adicionales, como pruebas o cuarentena, independientemente de su lugar de salida en la UE. </p> <br/>
        <p>Éste pasaporte demuestra tres tipos de modalidades, las cuales consta que la persona: </p> <br/>
        <p>- Se realizó una prueba de antígenos o RT-PCR cuyo resultado fue negativo. </p>
        <p>- Ha sido anteriormente vacunada contra COVID-19.</p>
        <p>- Se recuperó del COVID-19.</p>
    
      </div>
    ),
  },
  {
    question: "¿Necesitas un seguro de asistencia al viajero?",
    answer: (
      <div>
        <p>
        Es obligatorio viajar con un seguro de viaje que cubra tus necesidades médicas, además de que es un requisito indispensable para viajar a los países del Espacio Schengen. Recuerda que viajar con asistencia al viajero podrá ayudarte diferentes situaciones dependiendo del plan que selecciones, como por ejemplo:
        </p>
        <br />
        <p>
          - Asistencia médica ante COVID-19 o enfermedades preexistentes.
        </p>
        <p>
          - Hospitalización.
        </p>
        <p>
          - Búsqueda de equipaje o compensación en caso de extravío definitivo. 
        </p>
        <p>
          - Repatriación sanitaria.
        </p>
        <p>
          - Asistencia y reembolso si ocurriera un robo de dispositivos móviles.
        </p>
        <br />
        <p>
        Si bien en diferentes países algunas vacunas pueden variar, consulta con tu asesor de seguros y viaja con toda la documentación necesaria para disfrutar de tus vacaciones en cualquier parte del mundo.
        </p>{" "}
       
      </div>
    ),
  },
];

export default faqs;
